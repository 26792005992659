import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Date from 'src/components/columnist/feeds/Date';
import Headline from 'src/components/columnist/feeds/Headline';
import Teaser from 'src/components/columnist/feeds/Teaser';

import styles from './LinkContainer.module.scss';

const LinkContainer = ({
  article,
  featureName,
  featureShortName,
  category,
  clickPosition,
  latest,
  withBorder,
}) => {
  const articleLink = {
    href: '/[category]/[shortName]/[...publishDate]',
    as: `/${category}/${featureShortName}/${moment(article.publishDate).format(
      'YYYY/MM/DD'
    )}`,
  };

  return (
    <article
      className={classNames(styles.wrapper, {
        [styles.wrapper_bordered]: withBorder,
      })}
    >
      <header>
        <Date date={article.publishDate} latest={latest} />
        <Headline
          link={articleLink}
          featureName={featureName}
          clickPosition={clickPosition}
          padded
        >
          {article.title}
        </Headline>
      </header>
      {article.teaser && (
        <Teaser
          link={articleLink}
          featureName={featureName}
          category={category}
          content={article.teaser}
          clickTitle={article.title}
          clickPosition={clickPosition}
        />
      )}
    </article>
  );
};

LinkContainer.propTypes = {
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  article: PropTypes.shape({
    publishDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
  }).isRequired,
  clickPosition: PropTypes.number.isRequired,
  withBorder: PropTypes.bool,
  latest: PropTypes.bool,
};

LinkContainer.defaultProps = {
  latest: false,
  withBorder: false,
};

export default LinkContainer;
