import React, { useEffect, useContext } from 'react';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import moment from 'moment';
import PropTypes from 'prop-types';

// Server-side
import { getFeatureData } from 'src/pages/api/service/v1/features/shortname/[featureShortName]';
import { getArchiveData } from 'src/pages/api/service/v1/articles/feature-archives/[shortName]';
import metaModel from 'src/lib/models/metadata/feature';
import shortNameRouteValidator from 'src/lib/validation/routes/shortNameRouteValidator';

// Client-side
import {
  FeatureContext,
  FeatureTemplate,
} from 'src/components/global/page-templates/Feature';
import {
  ContentSidebar,
  Content,
} from 'src/components/global/layouts/content-sidebar/ContentSidebar';
import Container from 'src/components/global/layouts/Container';
import FeedContainer from 'src/components/columnist/feeds/FeedContainer';
import Filters from 'src/components/global/links/filters/Filters';
import DearAbbyNewsletterForm from 'src/components/columnist/dear-abby/NewsletterForm';
import Recommendations from 'src/components/article/Recommendations';

// Advertising
import FullAdvertisingWrapper from 'src/components/ads/wrappers/FullAdvertisingWrapper';
import StickyAdvertisingWrapper from 'src/components/ads/wrappers/StickyAdvertisingWrapper';
import PageTargeting from 'src/components/ads/PageTargeting';

const Sidebar = dynamic(
  () =>
    import('src/components/global/layouts/content-sidebar/ContentSidebar').then(
      (mod) => mod.Sidebar
    ),
  { ssr: false }
);

const MobileFilters = dynamic(
  () => import('src/components/global/links/filters/Mobile'),
  { ssr: false }
);

const BreakpointBetween = dynamic(
  () =>
    import('src/components/global/Breakpoints').then(
      (module) => module.BreakpointBetween
    ),
  { ssr: false }
);

const Feature = ({ metadata, feature, articles, endDate }) => {
  const featureData = useContext(FeatureContext);

  useEffect(() => {
    featureData.set(feature);
  }, [feature, featureData]);

  return (
    <>
      <PageTargeting featureId={feature.featureId} pageType="feature" />
      <NextSeo
        title={metadata.title}
        description={metadata.description}
        canonical={`https://www.uexpress.com/${feature.category}/${feature.featureShortName}`}
        openGraph={{
          title: metadata.title,
          description: metadata.description,
          images: [
            {
              url: feature.featureAvatarUrls.small,
            },
          ],
        }}
      />
      <ContentSidebar>
        <Sidebar>
          {feature?.featureTopics && feature?.featureTopics.length > 0 && (
            <Filters
              type="topics"
              filters={feature.featureTopics}
              shortName={feature.featureShortName}
              category={feature.category}
              displayContext="desktop"
            />
          )}
          {feature.featureShortName === 'dearabby' && (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            >
              <DearAbbyNewsletterForm
                formId="da-newsletter-sidebar"
                displayContext="sidebar"
              />
            </GoogleReCaptchaProvider>
          )}
          <StickyAdvertisingWrapper adId="rail_1" />
        </Sidebar>
        <Content>
          {feature.featureTopics && feature.featureTopics.length > 0 && (
            <MobileFilters
              type="topics"
              filters={feature.featureTopics}
              shortName={feature.featureShortName}
              category={feature.category}
              displayContext="mobile"
            />
          )}
          <FeedContainer
            featureName={feature.featureName}
            featureShortName={feature.featureShortName}
            articles={articles}
            category={feature.category}
            feedType="latest"
            endDate={endDate}
          />
        </Content>
      </ContentSidebar>

      {feature.featureShortName === 'dearabby' && (
        <Container>
          <BreakpointBetween minBreakpoint="xs" maxBreakpoint="md">
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            >
              <DearAbbyNewsletterForm
                formId="da-newsletter-main"
                displayContext="feature"
                split
              />
            </GoogleReCaptchaProvider>
          </BreakpointBetween>
        </Container>
      )}

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <Recommendations content={feature.recommendations} />
      </Container>
    </>
  );
};

Feature.template = FeatureTemplate;

/* eslint-disable react/forbid-prop-types */
Feature.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.string).isRequired,
  feature: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
  endDate: PropTypes.string.isRequired,
};
/* eslint-enable react/forbid-prop-types */

export async function getServerSideProps(context) {
  const { shortName } = context.params;

  const isValidRoute = await shortNameRouteValidator(context.params);

  if (!isValidRoute) {
    return {
      notFound: true,
    };
  }

  const feature = await getFeatureData({
    shortName,
  }).then(async (response) => {
    const data = await response.json();
    return { status: response.status, data };
  });

  if (feature.status === 404) {
    return {
      notFound: true,
    };
  }

  const endDate = `${
    feature.data.archiveYears[feature.data.archiveYears.length - 1]
  }/01/01`;

  const articles = await getArchiveData({
    shortName,
    startDate: moment().format('YYYY/MM/DD'),
    endDate,
    count: 15,
    strictStartDate: false,
  }).then(async (response) => {
    const data = await response.json();
    return { status: response.status, data };
  });

  if (articles.status === 404) {
    return {
      notFound: true,
    };
  }

  const metadata = metaModel(shortName);

  return {
    props: {
      metadata,
      feature: feature.data,
      articles: articles.data,
      endDate,
    },
  };
}

export default Feature;
