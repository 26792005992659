import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Client-side
import Container from 'src/components/global/layouts/Container';
import Latest from 'src/components/columnist/feeds/Latest';
import LinkContainer from 'src/components/columnist/feeds/LinkContainer';
import LinkButton from 'src/components/global/buttons/LinkButton';
import DividerWithContent from 'src/components/global/dividers/DividerWithContent';
import StaticSectionHeader from 'src/components/global/section-headers/Static';
import Button from 'src/components/global/buttons/Button';
// Advertising
import FullAdvertisingWrapper from 'src/components/ads/wrappers/FullAdvertisingWrapper';

const Topics = ({
  featureName,
  featureShortName,
  category,
  articles,
  loadMoreArticles,
  currentPage,
  stopFeed,
  isLoading,
}) => {
  const archiveLink = {
    href: '/[category]/[shortName]/archives',
    as: `/${category}/${featureShortName}/archives`,
  };
  if (articles.length === 0) {
    return (
      <StaticSectionHeader priority={5} centered centeredMobile>
        Looks like we could not find
        <br /> any articles for this topic.
        <hr />
        <LinkButton
          category={category}
          href={`/${category}`}
          as={`/${category}`}
          clickLocation="body"
          clickTitle={`View All ${category} Articles`}
        >
          {`View All ${category} Articles`}
        </LinkButton>
      </StaticSectionHeader>
    );
  }
  return (
    <>
      <Latest
        article={articles[0]}
        category={category}
        featureName={featureName}
        featureShortName={featureShortName}
      />

      <FullAdvertisingWrapper inFeed padded />

      <Container>
        {articles.length > 1 &&
          articles
            .slice(1)
            .map((article, index, array) => (
              <LinkContainer
                key={uuidv4()}
                featureName={featureName}
                featureShortName={featureShortName}
                category={category}
                article={article}
                clickPosition={index + 2}
                withBorder={index !== array.length - 1}
              />
            ))}
        <DividerWithContent padded>
          {currentPage < 11 && !stopFeed ? (
            <Button
              onClick={() => loadMoreArticles()}
              category={category}
              ariaLabel="Load More Articles"
              disabled={isLoading}
            >
              {isLoading ? 'Loading' : 'Load More'}
            </Button>
          ) : (
            <LinkButton
              href={archiveLink.href}
              as={archiveLink.as}
              category={category}
              clickLocation="feed"
              clickTitle="view archives"
            >
              View Archives
            </LinkButton>
          )}
        </DividerWithContent>
      </Container>
    </>
  );
};

Topics.propTypes = {
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      teaser: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  loadMoreArticles: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  stopFeed: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Topics;
