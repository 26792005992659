import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import styles from './Teaser.module.scss';

const Teaser = ({
  link,
  category,
  content,
  featureName,
  clickTitle,
  clickPosition,
}) => {
  const truncateLeadParagraph = (text, count) => {
    if (text.length > count) return `${text.substring(0, count)}... `;
    return `${text}... `;
  };

  return (
    <p className={styles.teaser}>
      {truncateLeadParagraph(content, 200)}

      <Link href={link.href} as={link.as}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          className={classNames(
            styles.teaser__more,
            `${styles[`teaser__more_${category}`]}`
          )}
          onClick={() => {
            onPageNavigationEvent('feed', 'link', clickTitle);
            placementClickEvent('feed', clickPosition, clickTitle, featureName);
          }}
          onKeyUp={() => {
            onPageNavigationEvent('feed', 'link', clickTitle);
            placementClickEvent('feed', clickPosition, clickTitle, featureName);
          }}
        >
          more
          <span
            className={classNames(
              styles.teaser__moreDecorator,
              'oi',
              'oi-chevron-right'
            )}
          />
        </a>
      </Link>
    </p>
  );
};

Teaser.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired,
  }).isRequired,
  featureName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  clickTitle: PropTypes.string.isRequired,
  clickPosition: PropTypes.number.isRequired,
};

export default Teaser;
