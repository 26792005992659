import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { normalizeTopicLabel } from 'src/lib/models/topics/topics';
import { BreakpointBetween } from 'src/components/global/Breakpoints';
import Control from 'src/components/global/buttons/Control';
import Filters from 'src/components/global/links/filters/Filters';

import styles from './Mobile.module.scss';

const Mobile = ({ type, filters, shortName, category }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [controlText, setControlText] = useState('');
  const [activeFilter, setCurrentFilter] = useState(null);

  useEffect(() => {
    let queryParamMatches;

    if (type === 'archives') {
      queryParamMatches = filters.filter(
        (filter) => filter === router.query.year
      );

      setCurrentFilter(queryParamMatches.length ? queryParamMatches[0] : null);
    } else {
      queryParamMatches = filters.filter(
        (filter) => filter.slug === router.query.topic
      );

      setCurrentFilter(
        queryParamMatches.length
          ? normalizeTopicLabel(queryParamMatches[0].name)
          : null
      );
    }
  }, [type, filters, router]);

  useEffect(() => {
    if (activeFilter) {
      setControlText(
        `Filtered by <span class="${styles.mobile__activeFilter}">${activeFilter}</span>`
      );
    } else {
      setControlText(
        type === 'archives' ? 'Filter by Year' : 'Filter by Topic'
      );
    }
  }, [type, activeFilter]);

  return (
    <BreakpointBetween minBreakpoint="xs" maxBreakpoint="md">
      <div className={styles.mobile}>
        <div className={styles.mobile__filterNavigation}>
          <div className={styles.mobile__control}>
            <Control
              onClick={() => setOpen(!open)}
              ariaLabel={
                open ? 'Close Filter Navigation' : 'Open Filter Navigation'
              }
              ariaExpanded={open ? 'true' : 'false'}
              ariaControls="filter-navigation"
            >
              <span className={styles.mobile__controlText}>
                {ReactHtmlParser(controlText)}
              </span>
              <span
                className={classNames(
                  'oi',
                  `${open ? 'oi-chevron-top' : 'oi-chevron-bottom'}`
                )}
              />
            </Control>
          </div>
          <div className={styles.mobile__filters}>
            <nav
              id="filter-navigation"
              className={classNames(
                styles.mobile__filterContainer,
                `${
                  open
                    ? styles.mobile__filterContainer_open
                    : styles.mobile__filterContainer_closed
                }`
              )}
            >
              <Filters
                type={type}
                filters={filters}
                shortName={shortName}
                category={category}
                displayContext="mobile"
                onClick={() => setOpen(false)}
              />
            </nav>
          </div>
        </div>
      </div>
    </BreakpointBetween>
  );
};

Mobile.propTypes = {
  type: PropTypes.oneOf(['topics', 'archives']).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  shortName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default Mobile;
