import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import is from 'is_js';
import classNames from 'classnames';

import Date from 'src/components/columnist/feeds/Date';
import Headline from 'src/components/columnist/feeds/Headline';
import Teaser from 'src/components/columnist/feeds/Teaser';

import styles from './Latest.module.scss';

const Latest = ({ article, category, featureShortName, featureName }) => {
  const articleLink = {
    href: '/[category]/[shortName]/[...publishDate]',
    as: `/${category}/${featureShortName}/${moment(article.publishDate).format(
      'YYYY/MM/DD'
    )}`,
  };

  return (
    <div className={styles.latest}>
      <div
        className={classNames(
          styles.latest__container,
          `${
            styles[
              `latest__container_${category}${
                is.even(moment().date()) ? 'Even' : 'Odd'
              }`
            ]
          }`
        )}
      >
        <article className={styles.latest__content}>
          <div className={styles.latest__date}>
            <Date date={article.publishDate} latest />
          </div>
          <div className={styles.latest__teaser}>
            <div className={styles.latest__headline}>
              <Headline
                link={articleLink}
                featureName={featureName}
                clickPosition={1}
                padded
              >
                {article.title}
              </Headline>
            </div>
            {article.teaser && (
              <Teaser
                link={articleLink}
                featureName={featureName}
                category={category}
                content={article.teaser}
                clickTitle={article.title}
                clickPosition={1}
              />
            )}
          </div>
        </article>
      </div>
    </div>
  );
};

Latest.propTypes = {
  article: PropTypes.shape({
    publishDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
  }).isRequired,
  category: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
};

export default Latest;
