import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { onPageNavigationEvent } from 'src/lib/analytics';
import { normalizeTopicLabel } from 'src/lib/models/topics/topics';
import { isParentActive } from './utilities';

import styles from './Filters.module.scss';

const Filters = ({
  type,
  filters,
  shortName,
  category,
  displayContext,
  underline,
  onClick,
}) => {
  const router = useRouter();

  const columns = (items) => {
    const filterColumns = [];

    const itemsPerRow = Math.round(items.length / 3);
    let rowOneCount = itemsPerRow;
    let rowTwoCount = itemsPerRow;

    if (items.length % 3 === 1) {
      rowOneCount += 1;
      rowTwoCount += 1;
    }

    filterColumns.push(items.slice(1, rowOneCount));
    filterColumns.push(items.slice(rowOneCount, rowOneCount + rowTwoCount));
    filterColumns.push(items.slice(rowOneCount + rowTwoCount));

    return filterColumns;
  };

  const buildFilterLink = (filter) => {
    if (type === 'archives') {
      return {
        href: '/[category]/[shortName]/archives/[year]',
        as: `/${category}/${shortName}/archives/${filter}`,
        label: filter,
      };
    }
    return {
      href: '/[category]/[shortName]/[topic]',
      as: `/${category}/${shortName}/${filter.slug}`,
      label: normalizeTopicLabel(filter.name),
    };
  };

  const isFilterActive = (filter) => {
    if (type === 'archives' && router.query.year === filter) {
      return true;
    }
    if (type === 'topics' && router.query.topic === filter.slug) {
      return true;
    }
    return false;
  };

  const handleClick = (filter) => {
    if (onClick) {
      onClick();
    }

    const clickTitle = type === 'topics' ? filter.slug : filter;

    onPageNavigationEvent('filter', 'link', clickTitle);
  };

  const parentLink =
    type === 'archives'
      ? {
          label: filters[0],
          href: '/[category]/[shortName]/archives',
          as: `/${category}/${shortName}/archives`,
        }
      : {
          label: 'All Topics',
          href: '/[category]/[shortName]',
          as: `/${category}/${shortName}`,
        };

  const desktopStyles = classNames(styles.filters__desktop, {
    [styles.filters__desktop_underline]: underline,
  });

  return (
    <nav className={styles.filters}>
      {columns(filters).map((column, index) => (
        <div key={uuidv4()} className={styles.filters__column}>
          {index === 0 && (
            <div
              className={
                displayContext === 'mobile'
                  ? styles.filters__mobile
                  : desktopStyles
              }
            >
              <Link href={parentLink.href} as={parentLink.as}>
                <a
                  className={classNames(
                    styles.filters__link,
                    styles[`filters__link_${category}`],
                    {
                      [styles.filters__link_active]: isParentActive(
                        type,
                        router.asPath,
                        category,
                        shortName
                      ),
                    }
                  )}
                  onClick={() => handleClick(parentLink.label)}
                  onKeyUp={() => handleClick(parentLink.label)}
                  role="link"
                  tabIndex={0}
                >
                  {parentLink.label}
                </a>
              </Link>
            </div>
          )}
          {column.map((filter) => {
            const linkProps = buildFilterLink(filter);
            return (
              <div
                key={uuidv4()}
                className={
                  displayContext === 'mobile'
                    ? styles.filters__mobile
                    : desktopStyles
                }
              >
                <Link href={linkProps.href} as={linkProps.as}>
                  <a
                    className={classNames(
                      styles.filters__link,
                      styles[`filters__link_${category}`],
                      {
                        [styles.filters__link_active]: isFilterActive(filter),
                      }
                    )}
                    onClick={() => handleClick(filter)}
                    onKeyUp={() => handleClick(filter)}
                    role="link"
                    tabIndex={0}
                  >
                    {linkProps.label}
                  </a>
                </Link>
              </div>
            );
          })}
        </div>
      ))}
    </nav>
  );
};

Filters.propTypes = {
  type: PropTypes.oneOf(['topics', 'archives']).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  shortName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  displayContext: PropTypes.string.isRequired,
  underline: PropTypes.bool,
  onClick: PropTypes.func,
};

Filters.defaultProps = {
  underline: false,
  onClick: undefined,
};

export default Filters;
